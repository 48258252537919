import React, {Component} from 'react'
import { Redirect } from 'react-router-dom'
import { Row, Col, Form, Button, Alert } from 'react-bootstrap'

class Connection extends Component {
    constructor(props) {
        super(props)
        this.state = {
            nickname: '',
            password: '',
            error: null
        }
    }

    handleChange = event => {
        const { name, value } = event.target

        this.setState({ [name]: value })
    }

    updateLogged = () => {
        this.props.update()
    }

    handleSubmit = event => {
        event.preventDefault()

        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

        const init = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({ "user_nickname" : this.state.nickname,  "user_password" : this.state.password })
        }

        fetch("https://checkout.muraro.dev/api/user/login_user.php", init)
            .then(
                res => {
                    if(res.ok) {
                        return res.json()
                    } else {
                        return false
                    }
                }
            )
            .then(
                (res) => {
                    if(res === false) {
                        this.setState({ error: "Nom d'utilisateur ou mot de passe incorrect !"})
                    } else {
                        if(res.isAdmin === "1") {
                            sessionStorage.setItem('isAdmin', true);
                        } else {
                            sessionStorage.setItem('isAdmin', false);
                        }

                        sessionStorage.setItem('nickname', this.state.nickname);

                        this.updateLogged()
                    }
                }

            )
    }

    render() {
        const {isLogged, error} = this.state
        let errorAlert = null

        if(isLogged) {
            return <Redirect push to={ `/dashboard` } />
        }


        if(error !== null) {
            errorAlert = <Alert variant="danger"> { error } </Alert>
        } else {
            errorAlert = null
        }

        return (
            <div className="App">
                <Row className="justify-content-md-center centered">
                    <Col>
                        <Form onSubmit={ this.handleSubmit }>
                            { errorAlert }

                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Nom d'utilisateur</Form.Label>
                                <Form.Control
                                    name="nickname"
                                    type="text"
                                    placeholder="Nom d'utilisateur"
                                    value={ this.state.pseudo }
                                    onChange={ this.handleChange }
                                />
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label>Mot de passe</Form.Label>
                                <Form.Control
                                    name="password"
                                    type="password"
                                    placeholder="Mot de passe"
                                    value={ this.state.password }
                                    onChange={ this.handleChange }
                                />
                            </Form.Group>
                            <Button variant="outline-primary" type="submit">
                                Se connecter
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Connection;
