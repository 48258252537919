import React, {Component} from 'react';
import Table from "react-bootstrap/Table";
import { Spinner, Button, Alert } from "react-bootstrap";
import UpdateUser from "./UpdateUser";

class TableUsers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            message: null,
            isLoaded: false,
            users: null,
            updateUser: false,
            id: null,
            nickname: null
        }
    }

    componentDidMount() {
        this.loadUser();
    }

    delete = (id) => {
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

        const init = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({ "user_id" : id })
        }

        fetch("https://checkout.muraro.dev/api/user/delete_user.php", init)
            .then(
                res => {
                    if(res.ok) {
                        return res.json()
                    } else {
                        return false
                    }
                }
            )
            .then(
                (res) => {
                    if(res === false) {
                        this.setState({ error: res.message })
                    } else {
                        this.setState({ message: res.message })
                        this.loadUser()
                    }
                }

            )
    }

    loadUser = () => {
        this.setState({ isLoaded: false, users: null })

        fetch("https://checkout.muraro.dev/api/user/view_user.php")
            .then(res => res.json())
            .then(
                (res) => {
                    this.setState({
                        isLoaded: true,
                        users: res
                    })
                },
                (error) => {
                    this.setState({
                        isLoaded: false,
                        error
                    })
                }
            )
    }

    handleShow = (id, nickname) => {
        this.setState({ updateUser: true, id: id, nickname: nickname})
    }

    render() {
        const { error, isLoaded, users, message } = this.state

        if (error) {
            return <div>Error: {error.message}</div>
        } else if (!isLoaded) {
            return (
                <div className="justify-content-md-center centered">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            )
        } else {
            return (
                <div>
                    { message ? ( <Alert variant="success"> { message } </Alert> ) : "" }

                    <Table responsive>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Nom d'utilisateur</th>
                                <th>Rôle</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((p, i) => (
                                <tr key={i}>
                                    <td>{ p.id }</td>
                                    <td>{ p.nickname }</td>
                                    <td>{ p.is_admin === "0" ? 'Non admin' : 'Admin' }</td>
                                    <td><Button variant="outline-dark"><i className="fas fa-user-edit"></i></Button></td>
                                    <td><Button type="button" onClick={() => { this.delete(p.id) }} variant="outline-danger"><i className="fas fa-user-minus"></i></Button></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            );
        }
    }
}

export default TableUsers;
