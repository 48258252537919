import React, {Component, Fragment} from 'react'
import {Row, Col, Card } from 'react-bootstrap'
import TableSales from "./TableSales";

class Dashboard extends Component {
    render() {
        return (
            <Fragment>
                <Row style={{margin: "10px 10px 10px 10px"}}>
                    <Col style={{textAlign: "left", marginRight: "5px"}}>
                        <Card body>
                            <Card.Title> Ventes du jour </Card.Title>
                            <Card.Text>
                                <TableSales />
                            </Card.Text>
                        </Card>
                    </Col>
                </Row>
            </Fragment>
        );
    }
}

export default Dashboard