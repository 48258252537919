import React, {Component} from 'react';
import Table from "react-bootstrap/Table";
import { Spinner, Button, Alert } from "react-bootstrap";

class TableUsers extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            message: null,
            isLoaded: false,
            products: null
        }
    }

    componentDidMount() {
        this.loadProducts();
    }

    delete = (id) => {
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

        const init = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({ "product_id" : id })
        }

        fetch("https://checkout.muraro.dev/api/product/delete_product.php", init)
            .then(
                res => {
                    if(res.ok) {
                        return res.json()
                    } else {
                        return false
                    }
                }
            )
            .then(
                (res) => {
                    if(res === false) {
                        this.setState({ error: res.message })
                    } else {
                        this.setState({ message: res.message })
                        this.loadProducts()
                    }
                }

            )
    }

    loadProducts = () => {
        fetch("https://checkout.muraro.dev/api/product/view_products.php")
            .then(res => res.json())
            .then(
                (res) => {
                    this.setState({
                        isLoaded: true,
                        products: res
                    })
                },
                (error) => {
                    this.setState({
                        isLoaded: false,
                        error
                    })
                }
            )
    }

    render() {
        const { error, isLoaded, products, message } = this.state

        if (error) {
            return <Alert variant="danger">Error: {error.message}</Alert>
        } else if (!isLoaded) {
            return (
                <div className="justify-content-md-center centered">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            )
        } else if (products.message) {
            return <Alert variant="warning"> Il y'a aucun produit </Alert>
        } else {
            return (
                <div>
                    { message ? ( <Alert variant="success"> { message } </Alert> ) : "" }

                    <Table responsive>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Nom du produit</th>
                                <th>Prix unitaire</th>
                                <th>Type de produit</th>
                                <th>Stock</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            { products.map((p, i) => (
                            <tr key={i}>
                                <td>{ p.id }</td>
                                <td>{ p.name }</td>
                                <td>{ p.unit_price }</td>
                                <td>{ p.type }</td>
                                <td>{ p.stock }</td>
                                <td><Button variant="outline-dark"><i className="fas fa-edit"></i></Button></td>
                                <td><Button type="button" onClick={() => { this.delete(p.id) }} variant="outline-danger"><i className="fas fa-minus-circle"></i></Button></td>
                            </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            );
        }
    }
}

export default TableUsers;
