import React, {Component} from 'react';
import {Table} from "react-bootstrap";

class TableSales extends Component {
    render() {
        const sales = [
            { name: "Pommes", quantity: "1339.8 Kg" },
            { name: "Coings", quantity: "72.90.-" },
            { name: "Noix", quantity: "29.40.-" },
            { name: "Courges", quantity: "17.40.-" }
        ]

        return (
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>Nom du produit</th>
                        <th>Quantité vendue</th>
                    </tr>
                </thead>
                <tbody>
                    { sales.map((a, i) =>
                        <tr key={i}>
                            <td>{ a.name }</td>
                            <td>{ a.quantity }</td>
                        </tr>
                    )}
                </tbody>
            </Table>
        );
    }
}

export default TableSales;