import React, {Component} from 'react';
import { Form, Col, Row, Button } from "react-bootstrap";


class AddUser extends Component {
    constructor(props) {
        super(props)

        this.state = {
            nickname: '',
            password: ''
        }
    }

    handleChange(event) {
        const {id, value} = event.target

        if(id === "nickname"){
            this.setState({ nickname: value })
        } else if(id === "password") {
            this.setState({ password: value })
        }
    }

    handleSubmit = () => {
        const myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

        const init = {
            method: "POST",
            headers: myHeaders,
            body: JSON.stringify({ "user_nickname" : this.state.nickname, "user_password" : this.state.password })
        }

        fetch("https://checkout.muraro.dev/api/user/create_user.php", init)
            .then(
                res => {
                    if(res.ok) {
                        return res.json()
                    } else {
                        return false
                    }
                }
            )
            .then(
                (res) => {
                    if(res === false) {
                        this.setState({ error: res.message })
                    } else {
                        this.setState({ message: res.message })
                        this.loadUser()
                    }
                }

            )
    }

    render() {
        return (
            <div style={{ marginBottom: 20}}>
                <Form onSubmit={this.handleSubmit()}>
                    <Row>
                        <Col>
                            <Form.Control id="nickname" placeholder="Nom d'utilisateur" type="text" onChange={this.handleChange} value={this.state.nickname} />
                        </Col>
                        <Col>
                            <Form.Control id="password" placeholder="Mot de passe" type="password" onChange={this.handleChange} value={this.state.password} />
                        </Col>
                        <Col>
                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    }
}

export default AddUser;
