import React, {Component} from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Redirect, HashRouter} from 'react-router-dom'
import Navigation from "./components/Navigation";
import Dashboard from './components/Dashboard/Dashboard'
import Sales from "./components/Sales";
import Connection from "./components/SignIn/Connection";
import Configurations from "./components/Configurations";
import UpdateUser from "./components/Configurations/components/UpdateUser";

class App extends Component {
    state = {
        nickname: null,
        isAdmin: false
    }

    componentDidMount() {
        this.setState({ nickname: sessionStorage.getItem('nickname'), isAdmin: sessionStorage.getItem('isAdmin') })
    }

    updateLogged = () => {
        this.setState({
            nickname: sessionStorage.getItem('nickname'),
            isAdmin: sessionStorage.getItem('isAdmin')
        })
    }

    logout = () => {
        sessionStorage.removeItem('nickname')
        sessionStorage.removeItem('isAdmin')

        this.updateLogged()
    }

    render() {
        return (
            <HashRouter>
                <Navigation />

                <Route exact path="/dashboard" render = {() => (
                    this.state.nickname ? (
                        <Dashboard />
                    ) : (
                        <Redirect to="/" />
                    )
                )} />

                <Route path="/sales" render = {() => (
                    this.state.nickname ? (
                        <Sales />
                    ) : (
                        <Redirect to="/" />
                    )
                )} />

                <Route path="/administration" render = {() => (
                    this.state.nickname ? (
                        this.state.isAdmin ? (
                            <Configurations />
                        ) : (
                            <Redirect to="/" />
                        )
                    ) : (
                        <Redirect to="/" />
                    )
                )} />

                <Route path="/administration/updateUser" render = {() => (
                    this.state.nickname ? (
                        this.state.isAdmin ? (
                            <UpdateUser />
                        ) : (
                            <Redirect to="/" />
                        )
                    ) : (
                        <Redirect to="/" />
                    )
                )} />

                <Route exact path="/" render={() => (
                    !this.state.nickname ? (
                        <Connection update={ this.updateLogged } isLogged={ this.state.isLogged } isAdmin={ this.state.isAdmin } />
                    ) : (
                        <Redirect to="/dashboard" />
                    )
                )} />

                <Route path="/logout" render = {() => (
                    this.state.nickname ? (
                        this.logout()
                    ) : (
                        <Redirect to="/" />
                    )
                )} />
            </HashRouter>
        );
    }
}

export default App;
