import React, {Component} from 'react';

class UpdateUser extends Component {
    constructor(props) {
        super(props);


        this.state = {
            id: this.props.id,
            nickname: this.props.nickname,
            password: null
        };
    }

    render() {
        return (
            <div>
                Update {this.state.nickname}
            </div>
        );
    }
}

export default UpdateUser;