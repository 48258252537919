import React, {Component, Fragment } from 'react';
import {Row, Col, Card, Form, Button, Spinner, Alert} from 'react-bootstrap'

class Sales extends Component {
    constructor(props) {
        super(props)
        this.state = {
            error: null,
            isLoaded: false,
            products: null
        }
    }

    componentDidMount() {
        fetch("https://checkout.muraro.dev/api/product/sale.php")
            .then(res => res.json())
            .then(
                (res) => {
                    this.setState({
                        isLoaded: true,
                        products: res
                    })
                },
                (error) => {
                    this.setState({
                        isLoaded: false,
                        error
                    })
                }
            )
    }

    render() {
        const { error, isLoaded, products } = this.state

        if (error) {
            return <div>Error: {error.message}</div>
        } else if (!isLoaded) {
            return (
                <div className="justify-content-md-center centered">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            )
        } else if(products.message) {
            return <Alert variant="warning"> Il y'a aucun produit </Alert>
        } else {
            return (
                <Fragment>
                    <Row style={{ margin: "10px 10px 10px 10px" }}>
                        { products.map((p, i) => (
                            <Col key={i}>
                                <Card style={{ width: '18rem', marginBottom: "20px" }}>
                                    <Card.Img variant="top" src="" />
                                    <Card.Body>
                                        <Card.Title>{ p.name }</Card.Title>
                                        <Card.Text>
                                            <b>Prix unitaire :</b> { p.unit_price }.- / { p.product_unit }<br/>
                                            { p.type === "Vin" ? p.stock > 0 ? (<b style={{ color: "green" }}>Disponible ({p.stock})</b>): (<b style={{ color: "red" }}>Non disponible</b>) : null}
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer>
                                        <Row>
                                            <Col><Form.Control lg={1} type="number" placeholder="Quantité" min="0" /></Col>
                                            <Col><Button variant="outline-primary">Ajouter</Button></Col>
                                        </Row>
                                    </Card.Footer>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Fragment>
            );
        }
    }
}

export default Sales;
