import React, {Component, Fragment} from 'react';
import {Tabs, Tab } from "react-bootstrap";
import TableUsers from "./components/TableUsers";
import TableProducts from "./components/TableProducts";
import AddUser from "./components/AddUser";

class Index extends Component {
    render() {
        return (
            <Fragment>
                <Tabs className="tabs" defaultActiveKey="users" transition={false} id="noanim-tab-example">
                    <Tab className="tab" eventKey="users" title="Utilisateurs">
                        <TableUsers />
                    </Tab>
                    <Tab className="tab" eventKey="products" title="Produits">
                        <TableProducts />
                    </Tab>
                </Tabs>
            </Fragment>
        );
    }
}

export default Index;