import React, {Component} from 'react'
import logo from '../logo.svg';
import { Navbar, Nav } from 'react-bootstrap'
import {NavLink} from 'react-router-dom'

class Navigation extends Component {
    render() {
        let nav = null

        if(sessionStorage.getItem('nickname') && sessionStorage.getItem('isAdmin') === "true") {
            nav =
                <Nav className="mr-auto">
                    <NavLink to={"/dashboard"} className={"link"}>Dashboard</NavLink>
                    <NavLink to={"/sales"} className={"link"}>Ventes</NavLink>
                    <NavLink to={"/administration"} className={"link"}>Configuration</NavLink>
                    <NavLink className={"link"} to={"/logout"}>Deconnexion</NavLink>
                </Nav>
        } else if(sessionStorage.getItem('nickname')) {
            nav =
                <Nav className="mr-auto">
                    <NavLink to={"/dashboard"} className={"link"}>Dashboard</NavLink>
                    <NavLink to={"/sales"} className={"link"}>Ventes</NavLink>
                    <NavLink className={"link"} to={"/logout"}>Deconnexion</NavLink>
                </Nav>
        }

        return (
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Navbar.Brand>
                    <img
                        alt="Logo"
                        src={logo}
                        width="30"
                        height="30"
                        className="d-inline-block align-top"
                    />
                    { 'Checkout App' }
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    {nav}
                </Navbar.Collapse>
            </Navbar>
        );
    }
}

export default Navigation;